.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* custom stuff */
/* everything above came from npx create-react-app */

.internship-pic {
    /* border-radius: 20%; */
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    /* border: 3px solid green; */
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
hr {
    max-width: 640px;
    font-family: Courier, 'Courier New', monospace;
    line-height: 1.6;
    margin: 1.5em auto 0;
}

div#tab nav,
div#tab a,
div#tab button {
    font-family: Courier, 'Courier New', monospace;
    text-align: left;
}

.nav-pills {
    --bs-naborder-radius: 0;
}

.container {
    padding-left: 5em;
    padding-right: 5em;
}

#tabContent {
    flex-grow: 1;
}


.nav-pills .nav-link:hover {
    background-color: #d4d1d1;
    background-color: rgb(217, 243, 155);
    ;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: black;
    background-color: #939292;
    background-color: rgb(132 165 52);
    border-radius: 0;
}

.nav-link {
    color: black;
    transition: none;

}


.nav-link a,
.nav a {
    text-decoration: none;
    color: black;
    /* color: rgb(132 165 52);
    text-decoration: underline; */
}


.nav-link:focus,
.nav-link:hover {
    color: black;
    border-radius: 0;
}

a {
    color: rgb(132 165 52);
    text-decoration: underline;
    /* text-decoration: none; */
    /* color: black; */
}

.plant-baby {
    position: fixed;
    bottom: 0;
    left: 0;
}

.plant-baby img {
    max-width: 120px;
}

.modal-content,
.modal-content p {
    padding: 10px;
    text-align: center;
}

.modal-content h1 {
    color: rgb(132 165 52);
    font-weight: bold;
}

a#typeform {
    all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgb(132 165 52);
    color: #fff;
    font-size: 20px;
    border-radius: 25px;
    padding: 0 33px;
    font-weight: bold;
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    margin: 0;
    text-decoration: none;
    margin: 1.5em 0;
}

div#button-div {
    text-align: center;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: black;
}

.carousel-item.active:hover .overlay {
    opacity: 1;
}

.row.silly-goose-holder img#goose {
    max-width: 300px;
    animation: fly 50s linear infinite;
    top: 0;
    left: 0;
    transform: translateX(-120%) translateY(-120%) rotateZ(0);
    position: fixed;
    animation-delay: 1s;
    z-index: 999999;
    animation-iteration-count: 1;
}

@keyframes fly {

    98.001%,
    0% {
        display: block;
        transform: translateX(-200%) translateY(100vh) rotateZ(0deg)
    }

    15% {
        transform: translateX(100vw) translateY(-100%) rotateZ(180deg)
    }

    15.001%,
    18% {
        transform: translateX(100vw) translateY(-30%) rotateZ(0deg)
    }

    40% {
        transform: translateX(-200%) translateY(3vh) rotateZ(-180deg)
    }

    40.001%,
    43% {
        transform: translateX(-200%) translateY(-100%) rotateZ(-180deg)
    }

    65% {
        transform: translateX(100vw) translateY(50vh) rotateZ(0deg)
    }

    65.001%,
    68% {
        transform: translateX(20vw) translateY(-200%) rotateZ(180deg)
    }

    95% {
        transform: translateX(10vw) translateY(100vh) rotateZ(0deg)
    }
}

img.internship-pic.img-fluid.m-3:hover {
    cursor: pointer;
}

span.zoda-labels {
    font-style: italic;
    background: aliceblue;
    cursor: help;
}